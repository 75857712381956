/*
  CodeMirror Hints Styles
  ------------------------------------------------------------------------------
*/

.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: 0 2px 5px 0.6px fade-out($g0-obsidian, 0.8);
  border-radius: $radius;
  font-size: 12px;
  font-family: $code-font;
  font-weight: 600;
  max-height: 20em;
  overflow-y: auto;
  @include gradient-h($c-star, $c-pool);
}

li.CodeMirror-hint {
  margin: 0;
  padding: 6px 7px;
  line-height: 12px;
  white-space: pre;
  color: $c-neutrino;
  cursor: pointer;
}

li.CodeMirror-hint-active {
  @include gradient-h($c-comet, $c-laser);
  color: $g20-white;
}
