/*
  Wizard Checkbox Component
  ------------------------------------------------------------------------------
*/

.wizard-checkbox--group {
  margin-top: $ix-marg-c;
  color: $g11-sidewalk;
  transition: color 0.25s ease;
  font-weight: 500;

  .wizard-checkbox--label {
    margin-left: $ix-marg-b;
    font-size: 14px;
  }
  .wizard-checkbox--subtext{
    margin-left: $ix-marg-b+36;
    font-size: 11px;
  }
  &:hover {
    color: $g18-cloud;
    cursor: pointer;
  }
}