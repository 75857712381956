/*
   Icon Font
   -----------------------------------------------------------------------------
   Created using a tool called IcoMoon
   See more at http://icomoon.io
*/

@font-face {
  font-family: 'icomoon';
  src:    url('./fonts/icomoon.eot');
  src:    url('./fonts/icomoon.eot') format('embedded-opentype'),
      url('./fonts/icomoon.woff2') format('woff2'),
      url('./fonts/icomoon.ttf') format('truetype'),
      url('./fonts/icomoon.woff') format('woff'),
      url('./fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  // Better Font Rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.add-cell:before {content: "\e925";}
  &.alert-triangle:before {content: "\e926";}
  &.alerts:before {content: "\e921";}
  &.annotate:before {content: "\e953";}
  &.annotate-plus:before {content: "\e952";}
  &.authzero:before {content: "\e951";}
  &.bar-chart:before {content: "\e913";}
  &.capacitor2:before {content: "\e923";}
  &.caret-down:before {content: "\e902";}
  &.caret-left:before {content: "\e901";}
  &.caret-right:before {content: "\e903";}
  &.caret-up:before {content: "\e906";}
  &.checkmark:before {content: "\e918";}
  &.circle:before {content: "\e940";}
  &.circle-thick:before {content: "\e90e";}
  &.clock:before {content: "\e920";}
  &.cog-outline:before {content: "\e91c";}
  &.cog-thick:before {content: "\e90c";}
  &.collapse:before {content: "\e91b";}
  &.crown-outline:before {content: "\e900";}
  &.crown2:before {content: "\e94b";}
  &.cube:before {content: "\e90b";}
  &.cubo-uniform:before {content: "\e91a";}
  &.dash-f:before {content: "\e927";}
  &.dash-h:before {content: "\e929";}
  &.dash-j:before {content: "\e92b";}
  &.disks:before {content: "\e950";}
  &.download:before {content: "\e91d";}
  &.duplicate:before {content: "\e917";}
  &.expand-a:before {content: "\e944";}
  &.expand-b:before {content: "\e942";}
  &.export:before {content: "\e90f";}
  &.eye:before {content: "\e91f";}
  &.eye-closed:before {content: "\e956";}
  &.eye-open:before {content: "\e957";}
  &.github:before {content: "\e928";}
  &.google:before {content: "\e92a";}
  &.graphline-2:before {content: "\e924";}
  &.group:before {content: "\e908";}
  &.heroku:before {content: "\e947";}
  &.heroku-simple:before {content: "\e948";}
  &.import:before {content: "\e910";}
  &.link:before {content: "\e92e";}
  &.oauth:before {content: "\e94f";}
  &.octagon:before {content: "\e92d";}
  &.okta:before {content: "\e912";}
  &.pause:before {content: "\e94a";}
  &.pencil:before {content: "\e92f";}
  &.play:before {content: "\e914";}
  &.plus:before {content: "\e90a";}
  &.plus-skinny:before {content: "\e922";}
  &.pulse-c:before {content: "\e936";}
  &.refresh:before {content: "\e949";}
  &.remove:before {content: "\e909";}
  &.search:before {content: "\e916";}
  &.server2:before {content: "\e94c";}
  &.shuffle:before {content: "\e94e";}
  &.square:before {content: "\e93e";}
  &.star:before {content: "\e930";}
  &.stop:before {content: "\e931";}
  &.text-block:before {content: "\e911";}
  &.trash:before {content: "\e905";}
  &.triangle:before {content: "\e92c";}
  &.user:before {content: "\e90d";}
  &.user-add:before {content: "\e907";}
  &.user-outline:before {content: "\e915";}
  &.user-remove:before {content: "\e904";}
  &.wood:before {content: "\e91e";}
  &.wrench:before {content: "\e919";}
  &.zap:before {content: "\e932";}
  &.chat:before {content: "\e933";}
}
