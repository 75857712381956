/*
  CodeMirror Styles
  ------------------------------------------------------------------------------
*/

.react-codemirror2 {
  position: relative;
  width: 100%;
  height: 100%;
}
.CodeMirror {
  border-radius: 0 0 $radius $radius;
  font-family: $code-font;
  background-color: transparent;
  color: $g13-mist;
  font-weight: 500;
  font-size: 13px;
  height: 100%;
}

.CodeMirror-vscrollbar {
  @include custom-scrollbar-round($g2-kevlar, $g6-smoke);
}

.CodeMirror-hscrollbar {
  @include custom-scrollbar-round($g0-obsidian, $g6-smoke);
}

.CodeMirror-gutters {
  @include gradient-v($g2-kevlar, $g0-obsidian);
  border: none;
}

.CodeMirror-gutters .CodeMirror-gutter {
  background-color: fade-out($g4-onyx, 0.75);
  height: calc(100% + 30px);
}

.CodeMirror-gutter.CodeMirror-linenumbers {
  width: 60px;
}

.CodeMirror .CodeMirror-sizer {
  margin-left: 60px;
}

.CodeMirror .CodeMirror-linenumber.CodeMirror-gutter-elt {
  padding-right: 9px;
  width: 46px;
  color: $g8-storm;
}

.CodeMirror-guttermarker,
.CodeMirror-guttermarker-subtle,
.CodeMirror-linenumber {
  color: rgb(83, 127, 126);
}

.CodeMirror-cursor {
  width: 2px;
  border: 0;
  background-color: $g20-white;
  box-shadow: 0 0 3px $c-laser, 0 0 6px $c-ocean, 0 0 11px $c-amethyst;
}

div.CodeMirror-selected,
.CodeMirror-focused div.CodeMirror-selected {
  background-color: fade-out($g8-storm, 0.7);
}

.CodeMirror-line::selection,
.CodeMirror-line>span::selection,
.CodeMirror-line>span>span::selection {
  background: rgba(255, 255, 255, 0.10);
}

.CodeMirror-line::-moz-selection,
.CodeMirror-line>span::-moz-selection,
.CodeMirror-line>span>span::-moz-selection {
  background: rgba(255, 255, 255, 0.10);
}

.CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0);
}

/*
  Themes & Sub-Components
  ------------------------------------------------------------------------------
*/
@import 'time-machine';
@import 'tickscript';
@import 'influxql';
@import 'influxql-read-only';
@import 'hints';
@import 'markdown';
