.raw-flux-data-table--cell {
  border: 1px solid $g4-onyx;
  font-family: $code-font;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;

  .raw-flux-data-table--cell-bg {
    background: $g3-castle;
    border: 2px solid $g3-castle;
    position: absolute;
    padding: 5px;
    border-radius: 2px;
    width: calc(100% - 10px);
    min-width: 100%;
    min-height: 100%;
    text-overflow:  ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: text;
    cursor: text;
  }

  &:hover {
    z-index: 2;

    .raw-flux-data-table--cell-bg {
      border: 2px solid $c-pool;
      background: $g5-pepper;
      width: auto;
    }
  }
}

