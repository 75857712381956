/*
  Cell Note Editor
  ------------------------------------------------------------------------------
*/

.cell-note-editor--container {
  width: 100%;
  height: 100%;
  background-color: $g3-castle;
}

.cell-note-editor {
  padding: 30px;
  min-width: 300px;
}

.cell-note-editor--header {
  font-size: 13px;
  font-weight: 600;
  color: $g11-sidewalk;
  @extend %no-user-select;
  margin: $ix-marg-b 0;
}

.cell-note-editor--field {
  background-color: $g2-kevlar;
  padding: $ix-marg-b;
  height: 320px;
  border: $ix-border solid $g5-pepper;
  border-radius: $radius;
  transition: border-color 0.25s ease, box-shadow 0.25s ease;

  &.focus {
    border-color: $c-pool;
    box-shadow: 0 0 6px 0 $c-pool;
  }
}

.cell-note-editor--display-option {
  display: flex;
  align-items: center;
  margin-top: $ix-marg-b;

  > label {
    flex: 1 0 0;
    margin-left: $ix-marg-b;
    color: $g11-sidewalk;
  }
}

.note-options---panel {
  background-color: $g3-castle;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.note-options--text {
  @extend %no-user-select;
  width: calc(100% - 60px);
  min-width: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    text-align: center;
    font-size: 15px;
    color: $g10-wolf;
    line-height: 1.5em;

    strong {
      color: $g14-chromium;
    }
  }
}