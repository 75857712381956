/*
  Form Styles
  ------------------------------------------------------------------------------
*/

$grid--form-gutter: 6px;

.form--wrapper {
  width: calc(100% + #{$grid--form-gutter * 2});
  margin-left: -$grid--form-gutter;
  margin-right: -$grid--form-gutter;
  display: inline-flex;
  flex-wrap: wrap;
}

/*
  Form Elements
  ------------------------------------------------------------------------------
*/
.form--element {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: $ix-marg-b;
}

.form--element.col {
  &-xs,
  &-sm,
  &-md,
  &-lg {
    &-12,
    &-11,
    &-10,
    &-9,
    &-8,
    &-7,
    &-6,
    &-5,
    &-4,
    &-3,
    &-2,
    &-1 {
      padding-left: $grid--form-gutter;
      padding-right: $grid--form-gutter;
    }
  }
}

/*
  Form Group Addons
  ------------------------------------------------------------------------------
*/
.form--label {
  width: 100%;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  color: $g11-sidewalk;
  margin: 0 0 $ix-marg-a 0;
  padding: 0 ($form-sm-padding + $ix-border);
  @extend %no-user-select;
}

.form--help-text {
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  font-weight: 600;
  color: $g11-sidewalk;
  margin: $ix-marg-a 0 0 0;
  padding: 0 ($form-sm-padding + $ix-border);
  @extend %no-user-select;
}

.form--element-error {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: $c-dreamsicle;
  margin: $ix-marg-a 0 0 0;
  padding: 0 ($form-sm-padding + $ix-border);
  @extend %no-user-select;
}

/*
  Form Divider
  ------------------------------------------------------------------------------
*/
.form--divider {
  width: 100%;
  height: $form-sm-height - $ix-marg-b;
}

/*
  Form Submit
  ------------------------------------------------------------------------------
*/
.form--submit {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: $form-sm-height - $ix-marg-b;
  margin-bottom: 0;

  > .button {
    margin: 0 $ix-marg-a;
  }
}
