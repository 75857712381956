/*
  CodeMirror "Markdown" Theme
  ------------------------------------------------------------------------------
  Intended for use with the Markdown CodeMirror Mode
*/

.cm-s-markdown {
  color: $g15-platinum;

  &:hover {
    cursor: text;
  }

  .cm-italic {
    font-style: italic;
  }

  .cm-bold {
    font-weight: 900;
  }

  .cm-strikethrough {
    text-decoration: none;
    opacity: 0.5;
  }

  .cm-heading {
    color: $c-honeydew;
  }

  .cm-image {
    color: $c-comet;
  }

  .cm-link {
    color: $c-pool;
    text-decoration: none;
  }

  .cm-blockquote {
    color: $g18-cloud;
    background-color: $g3-castle;
  }

  .CodeMirror-scrollbar-filler {
    background-color: transparent;
  }

  .CodeMirror-vscrollbar,
  .CodeMirror-hscrollbar {
    @include custom-scrollbar-round($g2-kevlar, $c-pool);
  }
}

.cm-s-markdown.CodeMirror-empty {
  font-style: italic;
  color: $g9-mountain;
}