/*
  Opt In Component
  ------------------------------------------------------------------------------
  User can toggle between a single value or any value
*/
.opt-in {
  display: flex;
  align-items: stretch;
  flex-wrap: nowrap;
}
.opt-in--container {
  display: flex;
  align-items: stretch;
  border: 2px solid $g5-pepper;
  border-left: 0;
  border-radius: 0 4px 4px 0;
}
.opt-in--label {
  user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -ms-user-select: none !important;
  -o-user-select: none !important;
  color: $c-pool;
  background-color: $g2-kevlar;
  font-family: $code-font;
  padding-right: 11px;
  line-height: 24px;
  font-size: 13px;
  font-weight: 500;
  transition: background-color 0.25s ease, color 0.25s ease;

  &:hover {
    cursor: pointer;
  }
  &:hover:active {
    cursor: pointer;
    color: $c-laser;
  }
}

.opt-in--groove-knob {
  width: 48px;
  position: relative;
  background-color: $g2-kevlar;

  &, &:hover {
    cursor: pointer;
  }

  &:before,
  &:after {
    position: absolute;
    top: 50%;
    content: '';
  }
  // Groove
  &:before {
    z-index: 2;
    width: 28px;
    height: 8px;
    border-radius: 4px;
    background-color: $g6-smoke;
    transform: translate(-50%,-50%);
    left: 50%;
  }
  // Knob
  &:after {
    z-index: 3;
    left: 50%;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $c-pool;
    transition: background-color 0.25s ease, transform 0.25s ease;
    transform: translate(0%, -50%);
  }
  // Gradient
  .opt-in--gradient {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include gradient-h($g2-kevlar,$g3-castle);
    transition: opacity 0.25s ease;
    opacity: 0;
  }
}

// Customize form input
.opt-in > input.form-control {
  border-radius: 4px 0 0 4px;
  font-family: $code-font;
  flex: 1 0 0;
  min-width: 0;
}
// Right value toggled state
.opt-in.right-toggled {
  .opt-in--groove-knob:after {
    transform: translate(-100%, -50%);
  }
  // Fade out left, fade in right
  .opt-in--gradient {
    opacity: 1;
  }
  // Make left label look disabled
  .opt-in--label {
    background-color: $g3-castle;
    color: $g8-storm;
    font-style: italic;

    &:hover {
      color: $c-pool;
    }
    &:hover:active {
      font-style: normal;
      color: $c-laser;
    }
  }
}
