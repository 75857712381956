/*
    Confirm Button
    ----------------------------------------------------------------------------
    This button requires a second click to confirm the action
*/

.confirm-button {
  .confirm-button--tooltip {
    visibility: hidden;
    transition: all;
    position: absolute;
    z-index: 1;

    &.top {
      bottom: calc(100% + 4px);
      left: 50%;
      transform: translateX(-50%);
    }
    &.bottom {
      top: calc(100% + 4px);
      left: 50%;
      transform: translateX(-50%);
    }
    &.left {
      top: 50%;
      right: calc(100% + 4px);
      transform: translateY(-50%);
    }
    &.right {
      top: 50%;
      left: calc(100% + 4px);
      transform: translateY(-50%);
    }
  }
}
.confirm-button--confirmation {
  white-space: pre;
  max-width: 210px;  
  border-radius: 3px;
  background-color: $c-curacao;
  opacity: 0;
  padding: 0 7px;
  color: $g20-white;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  transition:
    opacity 0.25s ease,
    background-color 0.25s ease;

  &:after {
    content: '';
    border: 8px solid transparent;
    position: absolute;
    transition: border-color 0.25s ease;
    z-index: 100;
  }

  &:hover {
    background-color: $c-dreamsicle;
    cursor: pointer;
  }

  .top &:after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: $c-curacao;
  }
  .top &:hover:after {border-top-color: $c-dreamsicle;}
  .bottom &:after {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-color: $c-curacao;
  }
  .bottom &:hover:after {border-bottom-color: $c-dreamsicle;}
  .left &:after {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-left-color: $c-curacao;
  }
  .left &:hover:after {border-left-color: $c-dreamsicle;}
  .right &:after {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-right-color: $c-curacao;
  }
  .right &:hover:after {border-right-color: $c-dreamsicle;}
}

.confirm-button.active {
  z-index: 999;

  .confirm-button--tooltip {
    visibility: visible;
  }
  .confirm-button--confirmation {
    opacity: 1;
  }
}
.confirm-button.btn.btn-default.btn-xs.btn-xxs {
  padding-right: 0;
  margin-top: -1px;

  &:hover {
    background-color:$g5-pepper;
    color: $g14-chromium;
  }

  span.icon.remove {
    margin-right: 0;
  }
}
