/*
    Source Selector component styles
    ----------------------------------------------------------------
*/

.source-selector {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex: 1 0 0;

  h3 {
    margin: 0 4px 0 0;
    font-size: 17px;
    color: $g13-mist;
    @include no-user-select();
  }

  .radio-buttons {
    margin: 5px;
  }
}
