$builder-card--margin: $ix-marg-b;
$builder-card--header-height: 30px;
$builder-card--header-margin: $ix-marg-b + $ix-border;

.builder-card--list {
  display: flex;
  flex: 1 1 0;
  flex-wrap: nowrap;
  position: absolute;
  height: 100%;

  > * {
    margin-left: $builder-card--margin * 0.5;
    margin-right: $builder-card--margin * 0.5;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.builder-card {
  border-radius: $radius;
  background-color: $g3-castle;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.builder-card--header {
  flex: 0 0 $builder-card--header-height;
  background-color: $g4-onyx;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.aggregation-selector-header {
    flex: 0 0 25px;
  }
}

.builder-card--title {
  display: inline-block;
  margin: 0 $builder-card--header-margin;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0;
  color: $g11-sidewalk;
}

.builder-card--delete {
  flex: 0 0 $builder-card--header-height;
  height: $builder-card--header-height;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 2px;
    border-radius: 1px;
    background-color: $g9-mountain;
    transition: background-color 0.25s ease;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    cursor: pointer;

    &:before,
    &:after {
      background-color: $c-dreamsicle;
    }
  }
}

.builder-card--hamburger {
  flex: 0 0 ($builder-card--header-height * 0.5);
  height: $ix-border;
  border-radius: $ix-border * 0.5;
  background-color: $g7-graphite;
  margin-left: $builder-card--header-margin;
  position: relative;
  transition: background-color 0.25s ease;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $g7-graphite;
    border-radius: $ix-border * 0.5;
    transition: background-color 0.25s ease;
  }

  &:before {
    transform: translateY(-200%);
  }

  &:after {
    transform: translateY(200%);
  }
}

.builder-card--draggable {
  display: flex;
  align-items: center;
  height: $builder-card--header-height;
  flex: 1 0 0;

  .builder-card--title {
    margin-left: $ix-marg-b;
    transition: color 0.25s ease;
  }

  &:hover {
    cursor: grab;
    .builder-card--title {
      color: $g15-platinum;
    }
    .builder-card--hamburger,
    .builder-card--hamburger:before,
    .builder-card--hamburger:after {
      background-color: $g15-platinum;
    }
  }
}

.builder-card--menu {
  border-bottom: $ix-border solid $g5-pepper;
  padding: $ix-marg-b;
  &.aggregation-selector-menu {
    padding: 2px;
  }
}

.builder-card--body {
  flex: 1 1 0;
  overflow: hidden;
  &.aggregation-selector-body {
    flex: unset;
    padding: 2px;
    overflow: visible;
  }
}

.builder-card--empty {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: $g9-mountain;

  > * {
    margin: $ix-marg-a 0;
  }
}

.builder-card--contents {
  padding: $ix-marg-b;
}

.builder-card--list > .query-builder--add-card-button {
  height: 100%;
  background-color: $g4-onyx;
  border-color: $g4-onyx;
  flex-grow: 0;
  flex-shrink: 0;
}

.cf-input-label.fill-values-checkbox--label {
  color: $g11-sidewalk;
}

.duration-input {
  position: relative;
  width: 100%;
}

.duration-input--menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: $g3-castle;
  z-index: 100;
}