/*
  Create / Edit Template Variable Overlay
  ------------------------------------------------------------------------------
*/

.temp-builder--mq-controls {
  background: $g3-castle;
  display: flex;
  padding: 2px 10px;

  &:first-of-type {
    padding-top: 10px;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }

  &:last-of-type {
    padding-bottom: 10px;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }

  > .temp-builder--mq-text,
  > .dropdown,
  .dropdown-placeholder {
    margin-right: 4px;
    flex-grow: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}

.temp-builder--mq-text {
  @include no-user-select();
  background-color: $g5-pepper;
  border-radius: $radius-small;
  padding: 0 8px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  color: $c-pool;
  font-size: 13px;
  font-weight: 600;
  font-family: $code-font;
}

textarea.temp-builder--flux {
  height: 100px;
}

@keyframes pulse {
  0% {
    color: $g19-ghost;
  }

  50% {
    color: $g12-forge;
  }

  100% {
    color: $g19-ghost;
  }
}

.temp-builder--validation {
  @include no-user-select();
  text-align: center;
  font-weight: 500;
  color: $g13-mist;
  margin: 0 0 8px 0;

  &.error {
    color: $c-fire;
    font-weight: 600;
  }

  &.warning {
    color: $c-pineapple;
    font-weight: 600;
  }

  &.loading {
    animation: pulse 1.5s ease infinite;
  }

  > strong {
    color: $c-comet;
  }

  &:only-child {
    margin-bottom: 0;
  }
}

.temp-builder--results {
  margin-top: 22px;
}

.temp-builder--results-list {
  max-height: 250px;
  padding: 0;
  margin: 0;
}

.temp-builder--results-item {
  @include no-user-select();
  background-color: $g3-castle;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-radius: $radius;
  font-size: 12px;
  margin: 0;
  color: $g13-mist;
  font-weight: 600;
  list-style: none;
  transition: color 0.25s ease, background-color 0.25s ease;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &.active {
    background-color: $g5-pepper;
    color: $g18-cloud;
  }
}

.temp-builder--default {
  color: $g9-mountain;
  text-transform: uppercase;
  font-size: 12px;
  display: inline-block;
  margin-left: 6px;
  opacity: 0;
  transition: color 0.25s ease, opacity 0.25s ease;
}

.temp-builder--results-item:hover .temp-builder--default {
  opacity: 1;
}

.temp-builder--results-item.active .temp-builder--default {
  color: $c-comet;
  opacity: 1;
}
