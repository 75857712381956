/*
  Dygraphs Styles
  ------------------------------------------------------------------------------
  Overrides out-of-the-box styles
*/

.dygraph {
  &:hover {
    cursor: default;
  }
}

/* Axis Labels */
.dygraph-axis-label {
  color: $g11-sidewalk !important;
  font-weight: 600 !important;
  user-select: none;
  font-size: 11px;
}
.dygraph-axis-label-y {
  padding: 0 9px 0 0 !important;
  text-align: right !important;
  left: 0 !important;
  user-select: none;
}
.dygraph-axis-label-y2 {
  padding: 0 0 0 9px !important;
  text-align: left !important;
  user-select: none;
}

/* Vertical Axis Labels */
.dygraph-ylabel,
.dygraph-y2label {
  user-select: none;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 11px;
  top: -12px !important;
  color: $g11-sidewalk;
  font-weight: 600;
  padding: 0 0 2px 0 !important;
  border-bottom: 2px solid $g4-onyx !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dygraph-ylabel {
  transform: rotate(-90deg);
}
.dygraph-y2label {
  transform: rotate(90deg);
}

.graph--hasYLabel {
  .dygraph-axis-label-y {
    padding: 0 4px 0 0 !important;
  }
  .dygraph-axis-label-y2 {
    padding: 0 0 0 4px !important;
  }
}
