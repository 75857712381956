/*
    Styles for Flux Builder aka TIME MACHINE aka DELOREAN
    ----------------------------------------------------------------------------
*/

@import '../components/time-machine/flux-overlay';
@import '../components/time-machine/flux-editor';
@import '../components/time-machine/flux-builder';
@import '../components/time-machine/flux-explorer';
@import '../components/time-machine/add-func-button';

.flux-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.flux-empty, .time-machine-vis {
  > p {
    color: $g11-sidewalk;
    font-size: 16px;
    @include no-user-select();
  }
}

.time-machine-vis {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $g3-castle;

  .loading-spinner .spinner div {
    background-color: $g10-wolf;
  }

  > p {
    text-align: center;
  }

  .dygraph-legend {
    top: 15px !important;
  }

  .dygraph-child {
    padding: 10px 50px 30px 30px;
    width: 100%;
    height: 100%;
    flex-shrink: 1;
    flex-grow: 1;
  }
}

.time-machine-vis--header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  font-weight: 600;
  font-size: 12px;
  color: $g16-pearl;
  flex-shrink: 0;
  flex-grow: 1;
}

.time-machine-vis--raw-toggle {
  display: flex;
  align-items: center;
  margin-right: $ix-marg-b;
  font-size: 13px;
  color: $g13-mist;
  font-weight: 600;
  @include no-user-select();

  .slide-toggle {
    margin-right: $ix-marg-b;
  }
}

.time-machine-vis .time-machine-tables {
  border-top: 1px solid $g5-pepper;
}

.time-machine-tables {
  display: flex;
  align-items: stretch;
  flex-wrap: none;
  width: 100%;
  height: 100%;
  background-color: $g3-castle;
  padding: 16px;
}

.time-machine-sidebar {
  width: 25%;
  min-width: $flux-builder-yield-tabs-min-width;
  max-width: $flux-builder-yield-tabs-max-width;
  background-color: $g2-kevlar;
  overflow: hidden;
  border-radius: $radius 0 0 $radius;
}

.time-machine-sidebar--heading {
  padding: $flux-node-padding;
  background: $g4-onyx;
}

.time-machines-sidebar--filter.form-control.input-xs {
  font-size: 12px;
}

.time-machine-sidebar--items {
  display: inline-flex;
  flex-direction: column;

}

.time-machine-sidebar-item {
  @include no-user-select();
  color: $g11-sidewalk;
  font-size: 12px;
  font-weight: 600;
  padding: 7px $flux-node-padding;
  transition: color 0.25s ease, background-color 0.25s ease;
  white-space: nowrap;

  &:hover {
    background-color: $g4-onyx;
    color: $g15-platinum;
    cursor: pointer;
  }

  &.active {
    background-color: $g5-pepper;
    color: $g18-cloud;
  }

  > span {
    padding-right: 1px;
    padding-left: 1px;
  }

  > span.key {
    color: $g9-mountain;
  }

  > span.value {
    padding-right: 5px;
    color: $g11-sidewalk;
  }
}

.time-machine-table {
  width: calc(100% - 32px);
  border: 2px solid $g5-pepper;
  border-radius: 3px;
  overflow: hidden;
  
  &:only-child {
    height: calc(100% - 16px);
    top: 8px;
    left: 16px;
    border: 1;
  }
}

.time-machine-vis, .yield-node--graph {
  .dygraph-child {
    width: 100%;
    height: 100%;
  }

  .dygraph-child-container {
    width: 100% !important;
    height: 100% !important;
  }
}
