/*
  Cell Editor Overlay - Display Options
  ------------------------------------------------------------------------------
*/

$graph-type--gutter: 4px;

.display-options {
  background-color: $g3-castle;
}

.display-options--wrapper {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
  padding: 30px;
  min-width: 500px;
}

.display-options--header {
  margin: 0 0 12px 0;
  font-weight: 400;
  color: $g11-sidewalk;
  @include no-user-select();
}

.display-options--footnote {
  color: $g11-sidewalk;
  margin: 0;
  margin-top: 8px;
  font-style: italic;
  display: inline-block;
  width: 100%;
  padding-left: 6px;
  @include no-user-select();
}

/*
  Cell Editor Overlay - Gauge Controls
  ------------------------------------------------------------------------------
*/
.gauge-controls {
  width: 100%;
}

.gauge-controls--section {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  height: 30px;
  margin-top: 8px;

  > * {
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
    }
  }
}
button.btn.btn-primary.btn-sm.gauge-controls--add-threshold {
  width: 100%;
}

%gauge-controls-label-styles {
  height: 30px;
  line-height: 30px;
  font-weight: 600;
  font-size: 13px;
  padding: 0 11px;
  border-radius: 4px;
  @include no-user-select();
}
.gauge-controls--label {
  @extend %gauge-controls-label-styles;
  color: $g11-sidewalk;
  background-color: $g4-onyx;
  width: 120px;
}
.gauge-controls--label-editable {
  @extend %gauge-controls-label-styles;
  color: $g16-pearl;
  width: 90px;
}

.gauge-controls--input {
  flex: 1 0 0;
}
.gauge-controls--section .color-dropdown.color-dropdown--stretch {
  width: auto;
  flex: 1 0 0;
}

/*
  Cell Editor Overlay - Single-Stat Controls
  ------------------------------------------------------------------------------
*/
.graph-options-group {
  margin-top: 30px;
}
.form-group-wrapper {
  display: inline-flex;
  flex-wrap: wrap;
  width: calc(100% + 12px);
  margin-left: -6px;
  margin-right: -6px;

  .form-group:last-of-type {
    margin-bottom: 0;
  }
}
