.kapacitor-step--dropdown {
  padding: 0;
}

.kapacitor-dropdown {
  min-width: 100%;
  .dropdown,
  .dropdown-toggle {
    min-width: 100%;
  }
}