.page-spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-spinner {
  border: 4px solid rgba(51, 51, 51, 0.4);
  border-left-color: $c-pool;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%,-50%);
  animation: pageSpinner 0.8s infinite linear;
}

@keyframes pageSpinner {
  0% {
    transform: translate(-50%,-50%) rotate(0deg);
    border-left-color: $c-pool;
  }

  25% {
    border-left-color: $c-comet;
  }

  50% {
    border-left-color: $c-pool;
  }

  75% {
    border-left-color: $c-rainforest;
  }

  100% {
    transform: translate(-50%,-50%) rotate(360deg);
    border-left-color: $c-pool;
  }
}
