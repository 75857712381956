/*
  Floating Legend Styles
  ------------------------------------------------------------------------------
*/

$dygraph-legend-z: 500;

.dygraph-child-container .dygraph-legend {
  display: none !important; // hide default legend
}
.dygraph-legend {
  background-color: $g0-obsidian;
  display: block !important;
  position: fixed;
  top: 0;
  left: 0;
  padding: 8px;
  z-index: $dygraph-legend-z;
  border-radius: 3px;
  user-select: text;
  @extend %drop-shadow;

  &.hidden {
    display: none !important;
  }
}

.dygraph-legend--header {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  > .btn {
    margin-left: 4px;
  }
}

.dygraph-legend--timestamp {
  margin-right: 8px;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
  color: $g13-mist;
  flex: 1 0 0;
}

.dygraph-legend--filter {
  flex: 1 0 0;
  margin-top: 8px;
}

.dygraph-legend--contents {
  font-size: 13px;
  color: $g15-platinum;
  font-weight: 600;
  line-height: 13px;
  max-height: 123px;
  margin-top: 8px;
  overflow-y: auto;
  @include custom-scrollbar-round($g0-obsidian, $g3-castle);
}

.dygraph-legend--row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  opacity: 1;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  padding: 3px 0;

  span {
    font-weight: 900;
    padding: 0;
    white-space: nowrap;
  }
  figure {
    white-space: nowrap;
    padding-left: 10px;
    font-family: $code-font;
  }

  &.highlight {
    opacity: 1;
    background-color: $g3-castle;
    figure {
      color: $g20-white;
    }
  }
  &.highlight:only-child {
    background-color: transparent;
  }
}

// Sorting Buttons
.sort-btn {
  position: relative;
}
.sort-btn--rotator {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.sort-btn--top,
.sort-btn--bottom {
  position: absolute;
  font-size: 10px;
  font-weight: 900;
  color: $g20-white;
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.sort-btn--top {
  left: 3px;
  top: -3px;
}
.sort-btn--bottom {
  bottom: -4px;
  left: 12px;
}

// Toggled State
.sort-btn.sort-btn--desc {
  .sort-btn--rotator {
    transform: rotate(180deg);
  }
  .sort-btn--top,
  .sort-btn--bottom {
    transform: rotate(-180deg);
  }
}