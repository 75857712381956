/*
  Slide Toggle Component
  ------------------------------------------------------------------------------
*/

.slide-toggle {
  background-color: $g1-raven;
  position: relative;
  padding: 0 ($ix-border * 2);
  display: inline-block;
  transition: background-color 0.25s ease, border-color 0.25s ease;
  border: $ix-border solid $g5-pepper;

  &:hover {
    cursor: pointer;
    border-color: $g6-smoke;
    background-color: $g2-kevlar;
  }
}

.slide-toggle--knob {
  position: absolute;
  top: 50%;
  transition:
    background-color 0.25s ease,
    transform 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  background-color: $g6-smoke;
  transform: translate(0,-50%);
  border-radius: 50%;

  .slide-toggle:hover & {
    background-color: $g8-storm;
  }
}

/* Active State */
.slide-toggle.active .slide-toggle--knob {
  background-color: $c-rainforest;
  transform: translate(100%,-50%);
}
.slide-toggle.active:hover .slide-toggle--knob {
  background-color: $c-honeydew;
}

/* Size Modifiers */
.slide-toggle {
  &.slide-toggle-xs {
    .slide-toggle--knob {
      width: $form-xs-height - 10px;
      height: $form-xs-height - 10px;
    }
    height: $form-xs-height;
    border-radius: $form-xs-height * 0.5;
    width: ($form-xs-height * 2) - 8px;
  }

  &.slide-toggle-sm {
    .slide-toggle--knob {
      width: $form-sm-height - 10px;
      height: $form-sm-height - 10px;
    }
    height: $form-sm-height;
    border-radius: $form-sm-height * 0.5;
    width: ($form-sm-height * 2) - 8px;
  }

  &.slide-toggle-md {
    .slide-toggle--knob {
      width: $form-md-height - 10px;
      height: $form-md-height - 10px;
    }
    height: $form-md-height;
    border-radius: $form-md-height * 0.5;
    width: ($form-md-height * 2) - 8px;
  }

  &.slide-toggle-lg {
    .slide-toggle--knob {
      width: $form-lg-height - 10px;
      height: $form-lg-height - 10px;
    }
    height: $form-lg-height;
    border-radius: $form-lg-height * 0.5;
    width: ($form-lg-height * 2) - 8px;
  }
}

/* Color Modifiers */
.slide-toggle {
  &.slide-toggle-default.active .slide-toggle--knob {
    background-color: $g13-mist;
  }
  &.slide-toggle-default.active:hover .slide-toggle--knob {
    background-color: $g15-platinum;
  }

  &.slide-toggle-primary.active .slide-toggle--knob {
    background-color: $c-pool;
  }
  &.slide-toggle-primary.active:hover .slide-toggle--knob {
    background-color: $c-laser;
  }

  &.slide-toggle-success.active .slide-toggle--knob {
    background-color: $c-rainforest;
  }
  &.slide-toggle-success.active:hover .slide-toggle--knob {
    background-color: $c-honeydew;
  }

  &.slide-toggle-warning.active .slide-toggle--knob {
    background-color: $c-star;
  }
  &.slide-toggle-warning.active:hover .slide-toggle--knob {
    background-color: $c-comet;
  }

  &.slide-toggle-danger.active .slide-toggle--knob {
    background-color: $c-curacao;
  }
  &.slide-toggle-danger.active:hover .slide-toggle--knob {
    background-color: $c-dreamsicle;
  }

  &.slide-toggle-alert.active .slide-toggle--knob {
    background-color: $c-pineapple;
  }
  &.slide-toggle-alert.active:hover .slide-toggle--knob {
    background-color: $c-thunder;
  }
}

/* Disabled State */
.slide-toggle.disabled {
  &,
  &:hover,
  &.active,
  &.active:hover {
    border-color: $g4-onyx;
    background-color: $g2-kevlar;
    cursor: not-allowed;
  }
  .slide-toggle--knob,
  &:hover .slide-toggle--knob,
  &.active .slide-toggle--knob,
  &.active:hover .slide-toggle--knob {
    opacity: 0.25;
  }
  &:not(.active) .slide-toggle--knob,
  &:not(.active):hover .slide-toggle--knob {
    background-color: $g6-smoke;
  }
}
