/*
  Single Statistic Cells
  ------------------------------------------------------------------------------
*/

.single-stat {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  overflow: hidden;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 3px;
  transition: background-color 0.25s ease, color 0.25s ease;
  @include no-user-select();
  color: $c-laser;

  > canvas.gauge {
    width: 100% !important;
    height: 100% !important;
  }
}

.dygraph-child > .single-stat {
  z-index: 3;
}

.single-stat--resizer {
  overflow: hidden;
  width: 88%;
  height: 69%;
}

.single-stat--text {
  font-family: $ix-text-font;
  font-weight: 300;
  letter-spacing: -0.06em;
}

.single-stat--value {
  position: relative;
  top: -16px;
  max-width: calc(100% - 32px);
  text-align: center;
  font-size: 54px;
  line-height: 54px;
  letter-spacing: -0.06em;
  font-weight: 300;
  color: inherit;
  z-index: 1;

  &.small {
    font-weight: 400;
    font-size: 34px;
    line-height: 34px;
  }
}

.single-stat--shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.single-stat--shadow:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 0;
  transform: translate(-50%, -50%);
  box-shadow: fade-out($g2-kevlar, 0.3) 0 0 50px 30px;
  z-index: -1;
}

.single-stat--value.small .single-stat--shadow:after {
  box-shadow: fade-out($g2-kevlar, 0.3) 0 0 30px 10px;
}