/*
  Full Screen Wizard Styles
  ------------------------------------------------------------------------------
*/

.wizard-splash {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 100%;
  min-height: 100%;
  background-color: $g0-obsidian;
  @include gradient-v($g2-kevlar,$g0-obsidian);
}

.wizard-container {
  padding: 30px 0;
  display: inline-flex;
  border-radius: $radius;
  width: 60%;
  min-width: 700px;
  max-width: 1000px;
  background-color: $g1-raven;
}