/*
  Variables
  ------------------------------------------------------
*/

$dash-graph-heading: 30px;
$dash-graph-heading-context: $dash-graph-heading - 8px;
$dash-graph-options-arrow: 8px;

/*
  Animations
  ------------------------------------------------------
*/

@keyframes refreshingSpinnerA {
  0% {
    transform: translate(-50%, -50%) scale(1.75);
    background-color: $g7-graphite;
  }
  33% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  66% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

@keyframes refreshingSpinnerB {
  0% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  33% {
    transform: translate(-50%, -50%) scale(1.75);
    background-color: $g7-graphite;
  }
  66% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

@keyframes refreshingSpinnerC {
  0% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  33% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
  66% {
    transform: translate(-50%, -50%) scale(1.75);
    background-color: $g7-graphite;
  }
  100% {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}

.dashboards-page-panel .generic-empty-state {
  margin: 90px 0;
}

/*
  Default Dashboard Mode
  ------------------------------------------------------
*/

%cell-styles {
  background-color: $g3-castle;
  border-radius: $ix-radius;
  border: 0;
  transition-property: left, top, background-color;
}

.dashboard {
  .react-grid-item {
    @extend %cell-styles;
  }
}

.dash-graph {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: $ix-radius;
  border: $ix-border solid $g3-castle;
}

.dash-graph--container {
  user-select: none;
  background-color: transparent;
  position: relative;
  height: calc(100% - #{$dash-graph-heading});
  padding: 0;
  .dygraph {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .dygraph .dygraph-child {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
  }
  .graph-panel__refreshing {
    top: (-$dash-graph-heading + 5px) !important;
  }
}

.dash-graph--note {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: $dash-graph-note-z;
  width: $dash-graph-heading;
  height: $dash-graph-heading;
}

.dash-graph--note-icon {
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 16px;
  height: 16px;
  color: $g20-white;
  opacity: 0.3;
  transition: opacity 0.25s ease;
  font-size: 14px;

  > .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.dash-graph--note-tooltip {
  display: none;
  position: absolute;
  z-index: 3;
  width: 290px;
  max-height: 140px;
  background-color: rgba($g0-obsidian, 0.95);
  border-radius: $radius;
  @extend %drop-shadow;

  .fancy-scroll--track-h {
    display: none;
  }
}

.dash-graph--note__top {
  top: -10px;
}
.dash-graph--note__middle {
  top: 50%;
  transform: translateY(-50%);
}
.dash-graph--note__bottom {
  bottom: 0;
}
.dash-graph--note__right {
  left: 100%;
}
.dash-graph--note__left {
  right: 100%;
}

.dash-graph--note-contents {
  padding: 14px 16px;
  font-size: 12px;
  color: $g13-mist;
}


.dash-graph--note:hover .dash-graph--note-tooltip {
  display: block;
}
.dash-graph--note:hover .dash-graph--note-icon {
  opacity: 1;
}

$dash-graph--heading-bar-z: 1;
$dash-graph--heading-dragger-z: $dash-graph--heading-bar-z + 1;
$dash-graph--heading-name-z: $dash-graph--heading-dragger-z + 1;

.dash-graph--heading {
  user-select: none;
  background-color: transparent;
  height: $dash-graph-heading;
  margin: 2px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: $dash-graph-header-z;
}
.dash-graph--heading-bar,
.dash-graph--heading-dragger {
  position: absolute;
  border-radius: $radius-small;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.dash-graph--heading-bar {
  z-index: $dash-graph--heading-bar-z;
  background-color: $g6-smoke;
}

.dash-graph--heading-dragger {
  z-index: $dash-graph--heading-dragger-z;
  @include gradient-h($c-pool, mix($c-pool, $c-comet));
}

.react-grid-item:hover {
  .dash-graph--heading-bar {
    opacity: 0.25;
  }
  .dash-graph--heading:hover .dash-graph--heading-bar {
    cursor: move;
    opacity: 0.5;
  }
}

.react-grid-item.resizing,
.react-grid-item.react-draggable-dragging {
  .dash-graph--heading-bar {
    opacity: 0;
  }
  .dash-graph--heading-dragger {
    opacity: 1;
  }
}

.dash-graph--name {
  font-size: 12px;
  font-weight: 600;
  border-radius: $radius;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  z-index: $dash-graph--heading-name-z;
  font-weight: 600;
  font-size: 13px;
  height: $dash-graph-heading;
  color: $g13-mist;
  line-height: $dash-graph-heading;
  width: calc(100% - 78px);
  padding-left: 10px;
  transition: color 0.25s ease, opacity 0.25s ease;
  
  .dash-graph--heading-draggable:hover & {
    color: $g16-pearl;
  }
}

.dash-graph--name.dash-graph--name__default {
  font-style: italic;
  opacity: 0.5;
}

.dash-graph--name.dash-graph--name__note {
  width: calc(100% - (78px + 22px));
  margin-left: 20px;
}

.dash-graph--draggable {
  cursor: move !important;
}

.dash-graph--custom-indicators {
  height: 24px;
  border-radius: 3px;
  display: flex;

  > .custom-indicator {
    font-size: 10px;
    line-height: 24px;
    padding: 0 7px;
    font-style: normal;
    font-family: $code-font;
    font-weight: 600;
    font-size: 12px;
    color: $c-pool;
    background-color: $g2-kevlar;
    margin-right: 2px;
    border-radius: 3px;
  }
}

.dash-graph-context {
  z-index: $dash-graph-context-z;
  position: absolute;
  top: $ix-border;
  right: 3px + $ix-border;
  height: 30px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  opacity: 0;
  transition: opacity 0.25s ease;
  pointer-events: none;
}

.react-grid-item:hover .dash-graph-context {
  opacity: 1;
}

.dash-graph-context.dash-graph-context__open {
  z-index: $dash-graph-context-expanded-z;
}

.dash-graph-context--buttons {
  display: flex;
  pointer-events: all;
}

.dash-graph-context--button {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  font-size: 12px;
  position: relative;
  color: $g13-mist;
  background-color: $g5-pepper;
  margin-right: 2px;
  transition: color 0.25s ease, background-color 0.25s ease;
  &:hover,
  &.active {
    cursor: pointer;
    color: $g20-white;
    background-color: $g7-graphite;
  }
  &:last-child {
    margin-right: 0;
  }
  > .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.active {
    position: relative;
    z-index: 20;
  }
}

.dash-graph-context--menu,
.dash-graph-context--menu.default {
  z-index: 3;
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  background-color: $g6-smoke;
  transform: translateX(-50%);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  &:before {
    position: absolute;
    content: '';
    border: 6px solid transparent;
    border-bottom-color: $g6-smoke;
    left: 50%;
    top: 0;
    transform: translate(-50%, -100%);
    transition: border-color 0.25s ease;
  }
  .dash-graph-context--menu-item {
    @include no-user-select();
    white-space: nowrap;
    font-size: 12px;
    font-weight: 700;
    line-height: 26px;
    height: 26px;
    padding: 0 10px;
    color: $g20-white;
    transition: background-color 0.25s ease;
    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &:hover {
      background-color: $g8-storm;
      cursor: pointer;
    }
    &.disabled,
    &.disabled:hover {
      cursor: default;
      background-color: transparent;
      font-style: italic;
      color: $g11-sidewalk;
    }
  }
}

.dash-graph-context--menu.primary {
  background-color: $c-ocean;
  &:before {
    border-bottom-color: $c-ocean;
  }
  .dash-graph-context--menu-item:hover {
    background-color: $c-pool;
  }
}

.dash-graph-context--menu.warning {
  background-color: $c-star;
  &:before {
    border-bottom-color: $c-star;
  }
  .dash-graph-context--menu-item:hover {
    background-color: $c-comet;
  }
}

.dash-graph-context--menu.success {
  background-color: $c-rainforest;
  &:before {
    border-bottom-color: $c-rainforest;
  }
  .dash-graph-context--menu-item:hover {
    background-color: $c-honeydew;
  }
}

.dash-graph-context--menu.danger {
  background-color: $c-curacao;
  &:before {
    border-bottom-color: $c-curacao;
  }
  .dash-graph-context--menu-item:hover {
    background-color: $c-dreamsicle;
  }
}

/* Presentation Mode */

.presentation-mode {
  .dash-graph-context {
    display: none;
  }
  .dash-graph--name {
    width: 100%;
  }
}

.graph-panel__refreshing {
  position: absolute;
  top: -1px !important;
  transform: translate(0, 0);
  right: 50%;
  transform: translateX(50%);
  width: 16px;
  height: 18px;
  > div {
    width: 4px;
    height: 4px;
    background-color: $g6-smoke;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  div:nth-child(1) {
    left: 0;
    animation: refreshingSpinnerA 0.8s cubic-bezier(0.645, 0.045, 0.355, 1)
      infinite;
  }
  div:nth-child(2) {
    left: 50%;
    animation: refreshingSpinnerB 0.8s cubic-bezier(0.645, 0.045, 0.355, 1)
      infinite;
  }
  div:nth-child(3) {
    left: 100%;
    animation: refreshingSpinnerC 0.8s cubic-bezier(0.645, 0.045, 0.355, 1)
      infinite;
  }
}

/*
  Interactive Styles
  ------------------------------------------------------
*/

// Dragging & Resizing Placeholder
@keyframes gridPlaceholderPulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.3;
  }
}

.react-grid-placeholder {
  @include gradient-diag-down($c-pool, $c-comet);
  border: 0 !important;
  opacity: 0.5;
  z-index: 2;
  border-radius: $radius !important;
  animation: gridPlaceholderPulse 3s ease-in-out infinite;
}

// Gradient Borders when Dragging or Resizing a Cell
$cell-gradient-border--start: $c-pool;
$cell-gradient-border--stop: $c-comet;
$cell-gradient-border--mid: mix($cell-gradient-border--start, $cell-gradient-border--stop);

.dash-graph--gradient-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  pointer-events: none;
  transition: opacity 0.25s ease;
  opacity: 0;
  z-index: $dash-graph-border-z;
}

.dash-graph--gradient-top-left,
.dash-graph--gradient-top-right,
.dash-graph--gradient-bottom-left,
.dash-graph--gradient-bottom-right {
  position: absolute;
  width: 50%;
  height: 50%;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }
}

.dash-graph--gradient-top-left {
  z-index: 2;
  top: 0;
  left: 0;
  border-top-left-radius: $ix-radius;
  border-top: $ix-border solid $cell-gradient-border--start;
  border-left: $ix-border solid $cell-gradient-border--start;

  &:before {
    top: -$ix-border;
    height: $ix-border;
    left: $ix-radius;
    width: calc(200% - #{$ix-radius * 2});
    @include gradient-h($cell-gradient-border--start, $cell-gradient-border--mid);
  }

  &:after {
    top: $ix-radius;
    height: calc(200% - #{$ix-radius * 2});
    left: -$ix-border;
    width: $ix-border;
    @include gradient-v($cell-gradient-border--start, $cell-gradient-border--mid);
  }
}

.dash-graph--gradient-top-right {
  top: 0;
  right: 0;
  border-top-right-radius: $ix-radius;
  border-top: $ix-border solid $cell-gradient-border--mid;
  border-right: $ix-border solid $cell-gradient-border--mid;
}

.dash-graph--gradient-bottom-left {
  bottom: 0;
  left: 0;
  border-bottom-left-radius: $ix-radius;
  border-bottom: $ix-border solid $cell-gradient-border--mid;
  border-left: $ix-border solid $cell-gradient-border--mid;
}

.dash-graph--gradient-bottom-right {
  z-index: 2;
  bottom: 0;
  right: 0;
  border-bottom-right-radius: $ix-radius;
  border-bottom: $ix-border solid $cell-gradient-border--stop;
  border-right: $ix-border solid $cell-gradient-border--stop;

  &:before {
    bottom: -$ix-border;
    height: $ix-border;
    right: $ix-radius;
    width: calc(200% - #{$ix-radius * 2});
    @include gradient-h($cell-gradient-border--mid, $cell-gradient-border--stop);
  }

  &:after {
    bottom: $ix-radius;
    height: calc(200% - #{$ix-radius * 2});
    right: -$ix-border;
    width: $ix-border;
    @include gradient-v($cell-gradient-border--mid, $cell-gradient-border--stop);
  }
}


.react-grid-item .dash-graph--gradient-border {
  display: none;
}

.react-grid-item.react-draggable .dash-graph--gradient-border,
.react-grid-item.react-resizable .dash-graph--gradient-border {
  display: block;
}

// Resize Handle
.react-grid-item .react-resizable-handle {
  background-image: none;
  cursor: nwse-resize;
  border: 15px solid transparent;
  border-right-color: $g6-smoke;
  border-bottom-color: $g6-smoke;
  border-bottom-right-radius: $ix-radius;
  z-index: $dash-graph-resizer-z;
  transition: border-color 0.25s ease, opacity 0.25s ease;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0;
  opacity: 0;
  
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    border-radius: 1px;
    background-color: $g0-obsidian;
    transition: background-color 0.25s ease;
    transform: translate(-50%, -50%) rotate(-45deg);
    border: 0;
  }
  &:before {
    width: 12px;
    top: 7px;
    left: 7px;
  }
  &:after {
    width: 20px;
    top: 4px;
    left: 4px;
  }
  &:hover {
    border-right-color: $cell-gradient-border--stop;
    border-bottom-color: $cell-gradient-border--stop;
  }
}

.react-grid-item:hover .react-resizable-handle {
  opacity: 1;
}


// Cell Resizing & Dragging Styles
.react-grid-item.resizing,
.react-grid-item.react-draggable-dragging {
  z-index: $dash-graph-interacting-z;

  .dash-graph--gradient-border {
    opacity: 1;
  }

  .dash-graph--name {
    color: $g20-white;
  }

  .dash-graph-context {
    opacity: 1;
  }

  .react-resizable-handle {
    border-right-color: $cell-gradient-border--stop;
    border-bottom-color: $cell-gradient-border--stop;

    &:before,
    &:after {
      background-color: $g0-obsidian;
    }
  }
}

// Cell Dragging Styles
.react-grid-item.react-draggable-dragging {
  &:hover {
    cursor: move;
  }

  .dash-graph--heading {
    cursor: move;
  }
}
