/*
  Wizard Progress Bar Styles
  ------------------------------------------------------------------------------
*/

.wizard-progress-bar {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: $ix-marg-b;
}

.wizard-progress-button {
  position: relative;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $ix-marg-b;
  
  .checkmark {
    color: $c-rainforest;
    transition: all .5s;
  }

  .circle-thick {
    color: $g7-graphite;
    transition: all .5s;
  }

  .current.circle-thick {
    color: $c-pool;
    transition: all .5s;
  }

  .remove {
    color: $c-curacao;
    transition: all .5s;
  }

  .wizard-progress-title {
    font-size: $ix-text-tiny;
    color: $g12-forge;
    opacity: 0;
    position: absolute;
    white-space: nowrap;
    margin-top: -$ix-marg-b;
    font-weight: 600;
    pointer-events: none;
    transition: all .5s;
    @include no-user-select();
  }
}

.wizard-progress-button:hover {
  .checkmark {
    color: $c-honeydew;
  }

  .circle-thick {
    color: $g12-forge;
  }

  .current.circle-thick {
    color: $c-laser;
  }

  .remove {
    color: $c-dreamsicle;
  }

  .wizard-progress-title {
    color: $g12-forge;
    opacity: 1;
    margin-top: -$ix-marg-c;
  }
}
