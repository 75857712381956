/*
  Template Control Bar
  ------------------------------------------------------
  Used within a dashboard to control values for
  template variables
*/

$template-control--margin: 2px;
$template-control--min-height: 52px;
$template-control-dropdown-min-width: 146px;
$template-control-dropdown-max-width: 300px;
$template-control--font-size: 12px;

.template-control-bar {
  position: relative;
  background-color: $g3-castle;
}
.template-control--container {
  width: 100%;
  min-height: $chronograf-page-header-height;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 60px;
}
.template-control--heading {
  font-size: 16px;
  font-weight: 500;
  color: $g13-mist;
  margin: 0 8px 0 16px;
  @include no-user-select();
  white-space: nowrap;
}
button.btn.template-control--manage {
  margin: 7px 0 7px 8px;
}
.template-control--controls {
  display: flex;
  flex: 1 0 0%;
  flex-wrap: wrap;
}
.template-control--empty {
  color: $g11-sidewalk;
  font-size: 14px;
  font-weight: 500;
  @include no-user-select();
}

.template-control--controls > .template-control--dropdown {
  flex: 0 1 auto;
  min-width: $template-control-dropdown-min-width;
  max-width: $template-control-dropdown-max-width;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: $template-control--margin;

  > .text-template-selector {
    order: 2;
    outline: none;
    border: 0;
    padding: 0 $form-xs-padding;
    height: $form-xs-height;
    border-radius: 0 0 $radius-small $radius-small;
    background-color: $g5-pepper;
    color: $g14-chromium;
    font-weight: bold;
    font-size: $template-control--font-size;
    font-family: $code-font;

    &:focus {
      color: $g18-cloud;
      background-color: $g7-graphite;
    }
  }

  > .dropdown {
    order: 2;
    margin: 0;
    flex: 1 0 0%;

    .dropdown-toggle {
      border-radius: 0 0 $radius-small $radius-small;
      width: 100%;
      font-size: $template-control--font-size;
      font-family: $code-font;
    }

    .dropdown-menu .fancy-scroll--view li.dropdown-item a {
      white-space: pre-wrap;
      word-break: break-all;
      overflow: hidden;
      font-family: $code-font;
      font-size: $template-control--font-size;
    }
  }
}

.template-control--label {
  @include no-user-select();
  order: 1;
  height: 22px;
  padding: 0 8px;
  margin: 0;
  font-size: 11px;
  font-family: $code-font;
  color: $c-potassium;
  line-height: 18px;
  border-radius: $radius-small $radius-small 0 0;
  background-color: $g4-onyx;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .icon {
    padding-bottom: 1px;
    color: $g11-sidewalk;
    cursor: pointer;
  }
}
