@keyframes wt-loading-dots {
  0% {
    content: ' ';
  }

  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }

  100% {
    content: ' ';
  }
}

.waiting-text::after {
  animation: 2s linear wt-loading-dots infinite;
  content: '...';
}
