/*
    Table Type Graphs in Dashboards
    ----------------------------------------------------------------------------
*/

.table-graph-container {
  position: absolute;
  width: calc(100% - 32px);
  height: calc(100% - 16px);
  top: 8px;
  left: 16px;
  border: 2px solid $g5-pepper;
  border-radius: 3px;
  overflow: hidden;
}

// removes default styling for scrollbar
.table-graph-cell::-webkit-scrollbar-track {
  border: none;
}

.table-graph-cell::-webkit-scrollbar {
  width: 0;
}

.table-graph-cell::-webkit-scrollbar-thumb {
  border: none;
}

.table-graph-cell {
  user-select: text !important;
  -o-user-select: text !important;
  -moz-user-select: text !important;
  -webkit-user-select: text !important;
  line-height: 28px; // Cell height - 2x border width
  padding: 0 6px;
  font-size: 12px;
  font-weight: 500;
  color: $g12-forge;
  border: 1px solid $g5-pepper;
  overflow-y: scroll;
  white-space: pre-line;
  text-overflow: ellipsis;

  &__highlight-row {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &__numerical {
    font-family: $code-font;
    text-align: right;
  }
  &__fixed-row,
  &__fixed-column {
    font-weight: 700;
    color: $g14-chromium;
    background-color: $g4-onyx;
  }
  &__fixed-row {
    border-top: 0;
  }
  &__fixed-column {
    border-left: 0;
  }
  &__fixed-corner {
    font-weight: 700;
    border-top: 0;
    border-left: 0;
    color: $g18-cloud;
    background-color: $g5-pepper;
  }
  &__field-name {
    padding-right: 17px;

    &:before {
      font-family: "icomoon";
      content: "\e902";
      font-size: 17px;
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%) rotate(180deg);
      font-size: 13px;
      opacity: 0;
      transition: opacity 0.25s ease, color 0.25s ease, transform 0.25s ease;
    }
    &:hover {
      cursor: pointer;
    }
    &:hover:before {
      opacity: 1;
    }
  }
  &__sort-asc,
  &__sort-desc {
    color: $c-pool;

    &:before {
      opacity: 1;
    }
  }
  &__sort-asc:before {
    transform: translateY(-50%) rotate(180deg);
  }
  &__sort-desc:before {
    transform: translateY(-50%) rotate(0deg);
  }
}

.ReactVirtualized__Grid {
  &:focus {
    outline: none;
  }
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &.table-graph--scroll-window {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;

      &-button {
        background-color: $g5-pepper;
      }
      &-track {
        background-color: $g5-pepper;
      }
      &-track-piece {
        background-color: $g5-pepper;
        border: 2px solid $g5-pepper;
        border-radius: 5px;
      }
      &-thumb {
        background-color: $g11-sidewalk;
        border: 2px solid $g5-pepper;
        border-radius: 5px;
      }
      &-corner {
        background-color: $g5-pepper;
      }
    }
    &::-webkit-resizer {
      background-color: $g5-pepper;
    }
  }
}
