/*
   Radio Buttons
   -----------------------------------------------------------------------------
*/

.radio-buttons {
  display: inline-flex;
  align-items: stretch;
}

.radio-button {
  @include no-user-select();
  font-family: $ix-text-font;
  font-weight: 600;
  transition: background-color 0.25s ease, color 0.25s ease;
  background-color: $g2-kevlar;
  color: $g11-sidewalk;
  text-transform: capitalize;
  outline: none;
  border: $ix-border solid $g5-pepper;
  border-right-width: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;

  &:first-child {
    border-top-left-radius: $ix-radius;
    border-bottom-left-radius: $ix-radius;
  }

  &:last-child {
    border-top-right-radius: $ix-radius;
    border-bottom-right-radius: $ix-radius;
    border-right-width: $ix-border;
  }

  &:hover {
    background-color: $g4-onyx;
    color: $g15-platinum;
    cursor: pointer;
  }

  &.active {
    background-color: $g5-pepper;
  }

  &.disabled,
  &.disabled:hover {
    background-color: $g2-kevlar;
    font-style: italic;
    color: $g7-graphite;
  }
}

/*  Size Modifiers */
@mixin radioButtonSizeModifier($fontSize, $padding, $height) {
  height: $height;

  .radio-button {
    height: $height;
    padding: 0 $padding;
    font-size: $fontSize;
  }
}

.radio-buttons--xs {
  @include radioButtonSizeModifier($form-xs-font, $form-xs-padding, $form-xs-height);
}
.radio-buttons--sm {
  @include radioButtonSizeModifier($form-sm-font, $form-sm-padding, $form-sm-height);
}
.radio-buttons--md {
  @include radioButtonSizeModifier($form-md-font, $form-md-padding, $form-md-height);
}
.radio-buttons--lg {
  @include radioButtonSizeModifier($form-lg-font, $form-lg-padding, $form-lg-height);
}


/* Color Modifiers */
.radio-buttons--default {
  .radio-button.active {
    color: $g18-cloud;
  }
}
.radio-buttons--primary {
  .radio-button.active {
    color: $c-pool;
  }
}
.radio-buttons--success {
  .radio-button.active {
    color: $c-rainforest;
  }
}
.radio-buttons--warning {
  .radio-button.active {
    color: $c-comet;
  }
}
.radio-buttons--danger {
  .radio-button.active {
    color: $c-dreamsicle;
  }
}
.radio-buttons--alert {
  .radio-button.active {
    color: $c-pineapple;
  }
}

/* Shape Modifiers */
.radio-buttons.radio-buttons--square {
  &.radio-buttons--xs .radio-button {
    width: $form-xs-height;
  }
  
  &.radio-buttons--sm .radio-button {
    width: $form-sm-height;
  }
  
  &.radio-buttons--md .radio-button {
    width: $form-md-height;
  }

  &.radio-buttons--lg .radio-button {
    width: $form-lg-height;
  }

  .radio-button {
    padding: 0;
  }
}

.radio-buttons.radio-buttons--stretch {
  width: 100%;

  .radio-button {
    flex: 1 0 0;
  }
}
