/*
  Wizard Controller Styles
  ------------------------------------------------------------------------------
*/

.wizard-step-title {
  text-align: center;
  @include no-user-select();
}

.wizard-controller {
  display: inline-flex;
  flex-direction: column;
  min-width: 100%;
  align-items: center;
}

.wizard-skip-link {
  flex-shrink: 0;
}

.wizard-tip-text {
  min-width: 100%;
  color: $g12-forge;
  text-align: center;
  p {
    font-weight: 600;
  }
}
