/*
  CodeMirror "Time Machine" Theme
  ------------------------------------------------------------------------------
  Intended for use with the Flux CodeMirror Mode
*/

.cm-s-time-machine {
  background-color: $g1-raven;
  color: $g11-sidewalk;

  .cm-variable {
    color: $c-pool;
    font-weight: 700;
  }
  .cm-function,
  .cm-pipe-forward {
    color: $c-comet;
    font-weight: 700;
  }
  .cm-arrow-function,
  .cm-function-arg,
  .cm-function-arg-ref {
    color: $c-pool; //#ff4d96;
  }
  .cm-operator {
    color: $g15-platinum;
  }
  .cm-argument {
    color: $g11-sidewalk;
  }
  .cm-string-single,
  .cm-string-double {
    color: $c-honeydew;
  }
  .cm-boolean {
    color: $c-viridian;
  }
  .cm-object {
    color: $c-honeydew;
  }
  .cm-null {
    color: $c-dreamsicle;
  }
  .cm-number {
    color: $c-hydrogen;
  }
  .cm-comment {
    color: $g8-storm;
  }

  .CodeMirror-scrollbar-filler {
    background-color: transparent;
  }

  .CodeMirror-vscrollbar,
  .CodeMirror-hscrollbar {
    @include custom-scrollbar-round($g1-raven, $c-pool);
    &::-webkit-scrollbar-thumb {
      border-width: 5px;
    }
  }
}
