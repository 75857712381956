/*
  Drag and Drop Styles
  ------------------------------------------------------------------------------
*/

.drag-and-drop--form {
  position: relative;
  z-index: $z--drag-n-drop;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: $g2-kevlar;
  border: 2px solid $g4-onyx;
  border-radius: 3px;
  padding: 30px 18px;
  transition: background-color 0.25s ease, border-color 0.25s ease;
}

input[type='file'].drag-and-drop--input {
  display: none;
}

.drag-and-drop--graphic {
  background-image: url('../../assets/images/drag-drop-icon.svg');
  background-size: 100% 100%;
  background-position: center center;
  width: 90px;
  height: 90px;

  &.success {
    background-image: url('../../assets/images/drag-drop-icon--success.svg');
  }
}

.drag-and-drop--header {
  @include no-user-select();
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 30px 0;
  font-size: 20px;
  font-weight: 400;

  &.empty {
    color: $g12-forge;
  }

  &.selected {
    color: $c-rainforest;
  }
}

.drag-and-drop--buttons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 18px;

  > button.btn {
    margin: 0 4px;
  }
}

/*
  Styles for hover state and drag-over state look the same
  ------------------------------------------------------------------------------
*/
.drag-and-drop--form.active:hover,
.drag-and-drop.drag-over .drag-and-drop--form {
  cursor: pointer;
  background-color: $g4-onyx;
  border-color: $g6-smoke;
}

/*
  Compact display mode
  ------------------------------------------------------------------------------
*/

.drag-and-drop.compact .drag-and-drop--form {
  flex-direction: row;

  .drag-and-drop--graphic,
  .drag-and-drop--buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .drag-and-drop--graphic {
    left: 20px;
    width: 50px;
    height: 50px;
  }

  .drag-and-drop--header {
    font-size: 15px;
    margin-bottom: 0;
    margin-right: 80px;
    margin-left: 80px;
    word-break: break-all;
    white-space: pre-wrap;
  }

  .drag-and-drop--buttons {
    margin-top: 0;
    right: 20px;
    flex-direction: column;
    align-items: stretch;

    > button.btn {
      margin: 2px 0;
    }
  }
}
