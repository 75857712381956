/*
  Wizard Button Bar Styles
  ------------------------------------------------------------------------------
*/

.wizard-button-bar {
  display: inline-flex;
  flex-shrink: 0;
  margin: $ix-marg-b auto $ix-marg-c auto;
  position: relative;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-wrap: nowrap;
  .btn {
    position: relative;
    white-space: nowrap;
    margin: 0 $ix-marg-a;
  }
}
